import { useTranslate } from "../../../../customHooks";
import { usePermissions } from '../../../../customHooks';
import { useCallback, useEffect, useMemo } from "react";
import { toast } from "../../../../components";
import { FormLayout, Input, Select, SubmitButton, RadioGroup, Checkbox } from "@panwds/react-form";
import { isEmpty } from 'lodash';
import { useUpdateLogProfileMutation, useGetLogProfileQuery } from "../../../../redux/services/log-profile-service";
import { useForm, FormProvider } from 'react-hook-form';
import { Button, LoadingPanel, Subtitle, Title, Tooltip } from "@panwds/react-ui";
import { InfoIcon } from "@panwds/icons";
import { useParams } from "react-router";
import { firewallStyles } from "../../style";

const FirewallLogProfile = (props: {accounts: Record<string, any>[]}) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const { firewallname } = useParams<{ firewallname: string }>();
    const region = useMemo(() => new URLSearchParams(location.search).get('region'), [location.search]);
    const [updaLogProfile] = useUpdateLogProfileMutation();
    const classes = firewallStyles();
    const { data: logProfile, error, isLoading, isFetching } = useGetLogProfileQuery({ payload: { FirewallId: firewallname, Region: region } });

    //Transform the list of available onboarded accounts to select options
    const linkedAccounts = useMemo(() => {
        if (!props?.accounts) {
            return [];
        }
        return props?.accounts?.map((account) => ({
            value: account,
            label: account,
        }));
    }, [props.accounts]);

    // useCallback function to transform the default form values
    const transformFormDefaultValue = useCallback((logProfile) => {
        if (isEmpty(logProfile)) {
            return {
                LogConfig: {
                    LogDestinationType: "none",
                }
            }
        }
        const { LogConfig } = logProfile;
        return {
            ...logProfile,
            LogConfig: {
                ...LogConfig,
                LogDestinationType: LogConfig?.LogDestinationType || "none",
            }
        }
    }, []);

    /**
    * Transforms the form data for firewall lof profile update submission.
    * This function processes the form data, applies necessary transformations,
    * and prepares the data structure required for the firewall log profile update.
    */
    const transformFormSubmitValue = useCallback((formData: Record<string, any>) => {
        if (isEmpty(formData)) {
            return;
        }
        if (formData?.LogConfig?.LogDestinationType === 'none') {
            delete formData['LogConfig'];
        }
        if (! formData['CloudWatchMetricsEnabled']) {
            delete formData['CloudwatchMetrics'];
        }
        delete formData['CloudWatchMetricsEnabled'];
        return formData;
    }, []);

    const formMethods = useForm();
    const {
        formState: { isDirty }, reset, watch, setValue,
    } = formMethods

      // Update form default values when the data is successfully fetched
    useEffect(() => {
        if (logProfile) {
            reset(transformFormDefaultValue(logProfile)); // Set the form's default values
        }
    }, [logProfile, reset]);

    useEffect(() => {
        if (error) {
            toast.error(error?.error, { toastId: `fetch-log-profile` });
            reset(transformFormDefaultValue(undefined));
        }
    }, [error]);

    const onSubmit = useCallback(
        async (data) => {
            try {
                const result = await updaLogProfile({
                    payload: transformFormSubmitValue(data),
                    region: region || '',
                }).unwrap();
                toast.success(translate(`resources.firewallsV2.logMetrics.logProfileUpdateSuccess`), { toastId: 'update-firewall-log-metrics' });
                if (result) {
                    reset(transformFormDefaultValue(result));
                }
            }
            catch (error: any) {
                toast.error(`${error?.code}: ${error?.error}`, {toastId: "update-firewall-log-metrics"});
            }
        }, [reset, permissions]
    );

    const handleCancel = () => {
        reset();
    };

    const CloudMetricsField = [{ children: "Dataplane CPU Utilization (%)", value: "Dataplane_CPU_Utilization" },
        { children: "Dataplane Packet Buffer Utilization (%)", value: "Dataplane_Packet_Buffer_Utilization" },
        { children: "Connection Per Second", value: "Connection_Per_Second" },
        { children: "Session Throughput Kbps", value: "Session_Throughput_Kbps" },
        { children: "Session Throughput Pps", value: "Session_Throughput_Pps" },
        { children: "Session Active", value: "Session_Active" },
        { children: "Session Utilization", value: "Session_Utilization" },
        { children: "Bytes In", value: "BytesIn" },
        { children: "Bytes Out", value: "BytesOut" },
        { children: "Packets In", value: "PktsIn" },
        { children: "Packets Out", value: "PktsOut" }];

    const logDestinationType = watch( "LogConfig.LogDestinationType");
    const policyManaged = watch("LogConfig.RoleType");
    const cloudWatchMetricsEnabled = watch("CloudWatchMetricsEnabled");

    useEffect(() => {
        // When log destination type is none make policy managed empty
        if(logDestinationType === 'none') {
            setValue('LogConfig.RoleType', '');
        } else if(logDestinationType === 'S3') {
            setValue('LogConfig.RoleType', 'ResourceBased');
        } else {
            setValue('LogConfig.RoleType', 'IamBased');
        }

      }, [logDestinationType]);

    return (
        <div className="tw-relative">
            {(isLoading || isFetching) &&
                <div className="tw-absolute tw-inset-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-backdrop-blur-sm tw-z-50" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                    <div className="tw-w-6 tw-h-6">
                        <LoadingPanel />
                    </div>
                </div>
            }
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <FormLayout>
                        <Title className={classes.fwEditHeader} level={2} size="xs">{translate(`resources.firewallsV2.logMetrics.title`)}</Title>
                        <Subtitle className="tw-font-lato tw-text-xs tw-text-secondary tw--pt-8">{translate(`resources.firewallsV2.logMetrics.subTitle`)}</Subtitle>

                        <RadioGroup horizontal
                            label={translate(`resources.firewallsV2.logMetrics.logDestinationType`)}
                            name="LogConfig.LogDestinationType"
                            dataMetrics={"cloudngfw-firewall-edit-log-destination-type"}
                            items={[{
                                value: 'S3',
                                children: <div className="tw-text-secondary tw-space-x-1 tw-flex tw-items-center">
                                    <span>{ translate(`resources.firewallsV2.logMetrics.s3`) }</span>
                                    <Tooltip label={translate(`resources.firewallsV2.logMetrics.s3Tooltip`)}>
                                            <span><InfoIcon className="tw-text-disabled"/></span>
                                        </Tooltip>
                                    </div>,
                            }, {
                                value: 'CloudWatchLogs',
                                children: <div className="tw-text-secondary tw-space-x-1 tw-flex tw-items-center">
                                    <span>{ translate(`resources.firewallsV2.logMetrics.cloudwatchLogGroup`) }</span>
                                    <Tooltip label={translate(`resources.firewallsV2.logMetrics.cloudWatchTooltip`)}>
                                        <span><InfoIcon className="tw-text-disabled"/></span>
                                    </Tooltip>
                                </div>
                            }, {
                                value: 'KinesisDataFirehose',
                                children: <div className="tw-text-secondary tw-space-x-1 tw-flex tw-items-center">
                                    <span>{ translate(`resources.firewallsV2.logMetrics.kinesisDataFirehose`) }</span>
                                    <Tooltip label={translate(`resources.firewallsV2.logMetrics.kinesisTooltip`)}>
                                        <span className="tw-ml-2"><InfoIcon className="tw-text-disabled"/></span>
                                    </Tooltip>
                                </div>
                            }, {
                                value: 'none',
                                children: <div className="tw-text-secondary">None</div>
                            }]}
                        />
                        {logDestinationType !== "none" && (
                        <>
                            <Select
                                items={[{
                                    value: 'TRAFFIC',
                                    children: translate(`resources.firewallsV2.logMetrics.traffic`)
                                }, {
                                    value: 'THREAT',
                                    children: translate(`resources.firewallsV2.logMetrics.threat`)
                                }, {
                                    value: 'DECRYPTION',
                                    children: translate(`resources.firewallsV2.logMetrics.decryption`)
                                }, {
                                    value: 'USER_ID',
                                    children: translate(`resources.firewallsV2.logMetrics.userId`)
                                }]}
                                label={translate(`resources.firewallsV2.logMetrics.logType`)}
                                name="LogConfig.LogType"
                                dataMetrics={"cloudngfw-firewall-edit-log-type"}
                                description={translate(`resources.firewallsV2.logMetrics.logTypeDescription`)}
                                placeholder=""
                                enableMultiSelect
                                simpleFormat
                                register={{ required: logDestinationType !== "none" }}
                                requiredAsterisk={logDestinationType !== 'none'}
                            />
                            <RadioGroup horizontal
                                label={translate(`resources.firewallsV2.logMetrics.policyType`)}
                                name="LogConfig.RoleType"
                                dataMetrics={"cloudngfw-firewall-edit-log-policy-type"}
                                register={{ required: logDestinationType !== "none" }}
                                items={[{
                                    disabled: logDestinationType !== "S3",
                                    value: 'ResourceBased',
                                    children: <div className="tw-text-secondary tw-space-x-1 tw-flex tw-items-center">
                                        <span>{translate(`resources.firewallsV2.logMetrics.resourceBasedPolicy`)}</span>
                                        <Tooltip label={translate(`resources.firewallsV2.logMetrics.roleBasedTooltip`)}>
                                            <span className="tw-ml-2"><InfoIcon className="tw-text-disabled"/></span>
                                        </Tooltip>
                                    </div>
                                }, {
                                    disabled: logDestinationType === "none",
                                    value: 'IamBased',
                                    children: <div className="tw-text-secondary tw-space-x-1 tw-flex tw-items-center">
                                        <span>{translate(`resources.firewallsV2.logMetrics.iamBasedPolicy`)}</span>
                                        <Tooltip label={translate(`resources.firewallsV2.logMetrics.iamBasedTooltip`)}>
                                            <span className="tw-ml-2"><InfoIcon className="tw-text-disabled"/></span>
                                        </Tooltip>
                                    </div>
                                }]}
                            />

                            {policyManaged === "IamBased" && (
                                <Select
                                    items={ linkedAccounts }
                                    label={translate(`resources.firewallsV2.endpointManagement.linkedAwsAccounts`)}
                                    name="LogConfig.AccountId"
                                    dataMetrics={"cloudngfw-firewall-edit-log-account-id"}
                                    requiredAsterisk
                                    simpleFormat
                                    placeholder=""
                                />
                            )}

                            <Input
                                label={translate(`resources.firewallsV2.logMetrics.logDestination`)}
                                name="LogConfig.LogDestination"
                                dataMetrics="cloudngfw-firewall-edit-log-destination"
                                requiredAsterisk={logDestinationType !== 'none'}
                                register={{ required: logDestinationType !== "none" }}
                            />
                        </>
                    )}

                        <Title level={2} size="xxs">{translate(`resources.firewallsV2.logMetrics.logGenerationOptions`)}</Title>

                        <Checkbox name="AdvancedThreatLog"
                            label={translate(`resources.firewallsV2.logMetrics.advancedThreatLog`)}
                            dataMetrics="cloudngfw-firewall-edit-advanced-threat-log-generation"
                            description={translate(`resources.firewallsV2.logMetrics.advancedThreatLogDescription`)}
                            defaultChecked={false}
                        />

                        <Title level={2} size="xxs">{translate(`resources.firewallsV2.logMetrics.metrics`)}</Title>

                        <Checkbox name="CloudWatchMetricsEnabled"
                            label={translate(`resources.firewallsV2.logMetrics.enableCloudWatchMetrics`)}
                            dataMetrics="cloudngfw-firewall-edit-cloudwatch-metrics-enable"
                            description={translate(`resources.firewallsV2.logMetrics.enableCloudWatchMetricsDescription`)}
                            defaultChecked={false}
                        />

                        {cloudWatchMetricsEnabled && (

                            <>
                                <Select
                                    items={ linkedAccounts }
                                    label={translate(`resources.firewallsV2.endpointManagement.linkedAwsAccounts`)}
                                    name="CloudwatchMetrics.AccountId"
                                    dataMetrics={"cloudngfw-firewall-edit-cloudwatch-metrics-account-id"}
                                    requiredAsterisk
                                    simpleFormat
                                    placeholder=""
                                />
                                <Input
                                    label={translate(`resources.firewallsV2.logMetrics.cloudWatchNamespace`)}
                                    name="CloudwatchMetrics.Namespace"
                                    dataMetrics={"cloudngfw-firewall-edit-cloudwatch-metrics-namespace"}
                                    requiredAsterisk
                                    tooltip={translate(`resources.firewallsV2.logMetrics.cloudWatchNamespaceTooltip`)}
                                />
                                <Select
                                    items={CloudMetricsField}
                                    label={translate(`resources.firewallsV2.logMetrics.cloudWatchMetrics`)}
                                    name="CloudwatchMetrics.Metrics"
                                    dataMetrics={"cloudngfw-firewall-edit-cloudwatch-metrics"}
                                    requiredAsterisk
                                    enableMultiSelect
                                    simpleFormat
                                    tooltip={translate(`resources.firewallsV2.logMetrics.cloudWatchMetricsTooltip`)}
                                    placeholder=""
                                    menuStyle={{width: '27rem'}}
                                />
                            </>
                        )}
                        <div className={classes.formFooterDivider}/>
                        <div className="tw-flex tw-justify-end">
                            <Button addClassName="tw-mr-2" onClick={handleCancel} disabled={!isDirty}>
                                {translate(`generic.cancel`)}
                            </Button>
                            <SubmitButton disabled={!isDirty}>
                                {translate(`generic.save`)}
                            </SubmitButton>
                        </div>
                    </FormLayout>
                </form>
            </FormProvider>
        </div>
    );
};export default FirewallLogProfile;
